<template>
  <!-- side nav-->
  <div id="SideNav" class="side-nav uk-animation-slide-left-medium">
    <div class="side-nav-bg"></div>
    <!-- logo -->
    <div class="logo uk-visible@s">
      <router-link to="/dashboard">
        <i class="uil-home-alt"></i>
      </router-link>
    </div>
    <ul>
      <!-- <li class="d-block d-sm-none">
        <router-link to="/dashboard">
          <i class="uil-home-alt"></i><span class="tooltips"> {{ $t("sideNav.Login") }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <a href="#">
          <i class="uil-book-open"></i
          ><span class="tooltips"> {{ $t("sideNav.My_education") }}</span></a
        >
        <div class="side-menu-slide">
          <div class="side-menu-slide-content">
            <ul data-simplebar>
              <li>
                <router-link :to="{name: 'user.my-education'}">
                  <i class="uil-book-open"></i> {{ $t("sideNav.My_education") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'user.my-assignment'}">
                  <i class="uil-book-open"></i>
                  {{ $t("sideNav.my_assigned_education") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li> -->
      <!-- 
      <li>
        <router-link :to="{ name: 'user.my-assignment' }">
          <i class="uil-book-open"></i><span class="tooltips"> {{ $t("sideNav.My_education") }}</span>
        </router-link>
      </li>

      <li v-if="false">
        <router-link to="/search">
          <i class="uil-search-alt"></i><span class="tooltips"> {{ $t("sideNav.Search") }}</span>
        </router-link>
      </li>

      <li>
        <router-link to="/my-statistics">
          <i class="uil-chart-line"></i>
          <span class="tooltips"> {{ $t("sideNav.My_statistics") }}</span>
        </router-link>
      </li> -->

      <!-- <li>
        <a href="#">
          <i class="uil-bolt-alt"></i
          ><span class="tooltips"> {{ $t("sideNav.My_duels") }}</span></a
        >
        <div class="side-menu-slide">
          <div class="side-menu-slide-content">
            <ul data-simplebar>
              <li>
                <router-link to="/duel">
                  <i class="uil-user-plus"></i> {{ $t("sideNav.Duel_Square") }}
                </router-link>
              </li>
              <li>
                <router-link to="/duel-result">
                  <i class="uil-clipboard-notes"></i>
                  {{ $t("sideNav.My_Duel_Results") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </li> -->

      <!-- <li>
          <a href="#"><i class="uil-presentation-edit"></i><span class="tooltips"> Sınıf İçi Etkileşimler</span> </a>
      </li> -->

      <!-- <li v-if="CollectionVisible">
        <router-link to="/my-collection">
          <i class="uil-notebooks"></i><span class="tooltips"> {{ $t("sideNav.My_collections") }}</span>
        </router-link>
      </li>

      <li v-if="this.$isCatalogTrainingVisible">
        <router-link to="/education-category">
          <i class="uil-books"></i>
          <span class="tooltips"> {{ $t("sideNav.Catalog_education") }}</span>
        </router-link>
      </li>

      <li v-if="this.$isGiftVisible">
        <router-link :to="{ name: 'User.gift' }">
          <i class="uil-gift"></i>
          <span class="tooltips"> {{ $t("sideNav.Gift_shop") }}</span>
        </router-link>
      </li>



      <li>
        <router-link to="/agenda">
          <i class="uil-calendar-alt"></i>
          <span class="tooltips"> {{ $t("sideNav.Agent") }}</span>
        </router-link>
      </li> -->
      <li v-for="(item, index) in moduleList.menu">
        <!-- <template v-if="item.type === 'my-collection'"> -->
        <router-link v-if="item.status === 1" :to="{ name: 'user.' + item.type }">
          <template v-if="item.type === 'my-assignment'">
            <i class="uil-book-open"></i><span class="tooltips"> {{ $t("sideNav.My_education") }}</span>
          </template>
          <template v-if="item.type === 'my-statistics'">
            <i class="uil-chart-line"></i>
            <span class="tooltips"> {{ $t("sideNav.My_statistics") }}</span>
          </template>
          <template v-if="item.type === 'my-collection'">
            <i class="uil-notebooks"></i><span class="tooltips"> {{ $t("sideNav.My_collections") }}</span>
          </template>
          <template v-if="item.type === 'education-category'">
            <i class="uil-books"></i>
            <span class="tooltips"> {{ $t("sideNav.Catalog_education") }}</span>
          </template>
          <template v-if="item.type === 'gift'">
            <i class="uil-gift"></i>
            <span class="tooltips"> {{ $t("sideNav.Gift_shop") }}</span>
          </template>
          <template v-if="item.type === 'wall'">
            <i class="uil-coffee"></i>
            <span class="tooltips"> {{ $t("sideNav.Wall") }}</span>
          </template>
          <template v-if="item.type === 'agenda'">
            <i class="uil-calendar-alt"></i>
            <span class="tooltips"> {{ $t("sideNav.Agent") }}</span>
          </template>

        </router-link>
        <!-- </template> -->
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
export default {
  name: "SideNav",
  data() {
    return {
      moduleList: []
    };
  },
  mounted() {
    axios.get(process.env.VUE_APP_BACKEND_APP_URL + "/api/widgets", {
      params: {
        company_id: this.authUser.company_id
      },
    }).then((response) => {
      this.moduleList = response.data;
      this.moduleList.menu = this.moduleList?.menu?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)) ?? null;
      // this.moduleList['menu'] = this.moduleList?.menu.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)) ?? null;
      ls.set("dashboardMenuList", this.moduleList);
    });
  },
};
</script>

<style scoped>
.router-link-exact-active {
  background-color: #F3F3F3;
}
</style>
