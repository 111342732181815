import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {},
});
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });
import { ref } from "vue";

// Reaktif global variable
export const subCompany = ref(null);
export const getSubDomainId = ref(
  ls.get("user") ? JSON.parse(ls.get("user")).company_id : null
);
