import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { Service } from "axios-middleware";
import router from "@/router";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });
const baseURL = process.env.VUE_APP_BACKEND_APP_URL;
const service = new Service(axios);
import { getSubDomainId } from "@/core/services/store.js";

const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "*",
    "Content-Type": "application/json",
    ...(getSubDomainId?.value && { company: getSubDomainId?.value }),
  },
};

service.register({
  onRequest(config) {
    if (config.url == baseURL + "/api/token") {
      delete config.headers.Authorization;
    }
    return config;
  },
});

let isRefreshing = false;
let failedQueue = [];

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = ls.get("token");
    const language = ls.get("language");
    if (token && config.url !== "api/token") {
      config.headers["Authorization"] = "Bearer " + token;
    }

    config.headers["X-Content-Language"] = language;
    config.headers["X-Softinya"] = "softinyacom";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 403) {
      router.push({ name: "403" });
      return Promise.reject(err);
    }

    if (
      err.response.status === 401 &&
      (originalRequest.url === "api/token" ||
        originalRequest.url === "api/logout" ||
        originalRequest.url === "api/auth-validation")
    ) {
      return Promise.reject(err);
    }

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      let refreshToken = ls.get("refresh_token");
      return new Promise(function (resolve, reject) {
        const unInterceptedAxiosInstance = axios.create();
        unInterceptedAxiosInstance
          .post(baseURL + "/api/token/refresh", {
            refresh_token: refreshToken,
          })
          .then(({ data }) => {
            ls.set("token", data.access_token);
            ls.set("refresh_token", data.refresh_token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.access_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.access_token;
            processQueue(null, data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            if (
              err.response.status === 401 ||
              err.response.status === 429 ||
              err.response.status === 422
            ) {
              let redirect_url = window.localStorage.getItem("redirect_url");

              // window.localStorage.clear();
              ls.removeAll();

              if (redirect_url && typeof redirect_url !== "undefined") {
                router.push({
                  name: "login",
                  query: { redirect: redirect_url },
                });
              } else {
                router.push({ name: "login" });
              }

              setTimeout(() => {
                // location.reload();
              }, 500);
            }
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = baseURL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    Vue.axios.defaults.headers.common["Access-Control-Allow-Methods"] =
      "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    Vue.axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.headers.common["X-Content-Language"] =
      ls.get("language");
    Vue.axios.defaults.headers.common["X-Softinya"] = "softinya.com";
    Vue.axios.defaults.headers.common["Authorization"] =
      "Bearer " + ls.get("token");
  },

  query(resource, params, config = CONFIG) {
    config.headers.Authorization = "Bearer " + ls.get("token");
    return Vue.axios.get(resource, params, config).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param config
   * @returns {*}
   */
  get(resource, slug = "", config = {}) {
    if (!config.headers) {
      config.headers = {};
    }

    const finalConfig = {
      ...CONFIG,
      headers: {
        ...CONFIG.headers,
        ...config.headers,
      },
    };

    const token = ls.get("token");
    if (token) {
      finalConfig.headers.Authorization = `Bearer ${token}`;
    }

    finalConfig.headers["Content-Type"] = "application/json";

    // console.log("finalConfig : ", finalConfig)
    // console.log("resources : ", `${resource}${slug ? "/" : ""}${slug}`)

    return Vue.axios
      .get(`${resource}${slug ? "/" : ""}${slug}`, finalConfig)
      .catch((error) => {
        throw new Error(`ApiService ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  post(resource, params, config = CONFIG) {
    config.headers.Authorization = "Bearer " + ls.get("token");
    config.headers["Content-Type"] = "application/json";
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, config = CONFIG) {
    config.headers.Authorization = "Bearer " + ls.get("token");
    config.headers["Content-Type"] = "application/json";
    return Vue.axios.put(
      `${resource}${slug ? "/" : ""}${slug}`,
      params,
      config
    );
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, config = CONFIG) {
    config.headers.Authorization = "Bearer " + ls.get("token");
    config.headers["Content-Type"] = "application/json";
    return Vue.axios.put(`${resource}`, params, config);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param config
   * @returns {*}
   */
  delete(resource, config = CONFIG) {
    config.headers.Authorization = "Bearer " + ls.get("token");
    return Vue.axios.delete(resource, config).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
